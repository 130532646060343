body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#page-layout{
  display: flex;
  margin-top: 90px;
}

#page-plus-bread {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-basis: 80%;
  flex-grow: 1;
  margin-left: 50px;
  margin-right: 50px;
}

#page-plus-bread-sd {
  margin-top: 15px;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-basis: 80%;
}

#docCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
  max-height: 50vh;
  overflow-y: auto;
  padding-bottom: 10px;
}

@media (max-width: 1450px) {
  #docCards {
    max-height: calc(100vh - 350px);
  }
}

@media (max-width: 1200px) {
  #docCards {
    max-height: 60vh;
  }
}

.docCardItem {
  height: 100%;
  margin-left: 2px;
  border: 1px solid #eeeeee;
  border-radius: 6px;
}

@media (max-width: 1200px) {
  .docCardItem {
    flex-basis: 45%;
  }
}

#overview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#upload-doc-button {
  height: 3rem;
  margin-right: 10%;
}

.p-breadcrumb ul {
  gap: 10px;
}

h1 {
  margin: 0px;
}

a {
  text-decoration: none;
}

